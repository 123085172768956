.root {
  height: calc(100vh - 180px);
  min-height: 590px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :global(.container) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #181818;
    .title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 56px;
      line-height: 112%;
      text-align: center;
      max-width: 900px;
    }
    .description {
      margin-top: 20px;
      margin-bottom: 40px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 150%;
      max-width: 686px;
      text-align: center;
    }
    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 40px;
    }
  }
}


@media screen and (max-width: 640px) {
  .root {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0;
    .wrapper {
      .title {
        font-size: 38px;
        line-height: 112%;
      }
      .description {
        font-size: 14px;
        line-height: 154%;
      }
      .buttons {
        gap: 16px;
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
  }
}
