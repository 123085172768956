.root {
  margin-bottom: 160px;

  .title {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 112%;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  .description {
    font-family: "Roboto", sans-serif;
    line-height: 150%;
    font-size: 17px;
    max-width: 590px;
    color: #97adc6;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    display: flex;
    gap: 70px;
    align-items: center;
    justify-content: center;
  }

  .points {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 384px;
  }

  .point {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .pointTitle {
    color: var(--181818, #181818);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Raleway;
    font-size: 22px;
    font-weight: 700;
    line-height: 132%;
  }

  .pointDescription {
    color: var(--181818, #181818);
    font-family: Roboto;
    font-size: 17px;
    line-height: 150%;
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}

@media screen and (max-width: 640px) {
  .root {
    margin-bottom: 80px;

    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 132%;
    }

    .description {
      font-size: 14px;
      line-height: 154%;
      margin-bottom: 32px;
      margin-top: 10px;
    }

    .content {
      flex-direction: column;
      gap: 32px;
      align-items: center;
    }

    .points {
      margin: 0 20px;
    }

    .pointTitle {
      font-size: 18px;
      line-height: 132%;
    }

    .pointDescription {
      font-size: 14px;
      line-height: 154%;
    }

    .buttons {
      flex-direction: column;
      gap: 16px;
    }
  }
}
