.root {
  background: #0084FF;
  padding-top: 80px;
  padding-bottom: 80px;
  .wrapper {
    .title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 112%;
      text-align: center;
      color: #FFFFFF;
    }
    .grid {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      padding-top: 80px;
      & > .col {
        display: flex;
        flex-direction: column;
        min-width: 283px;
        padding: 20px;
        box-sizing: border-box;
        &.inline {
          display: grid;
          grid-gap: 40px;
          grid-template-columns: repeat(auto-fit, minmax(131px, calc(50% - 20px)));
          min-width: 131px;
        }
        &[aria-colspan="1"] {
          width: calc(100% / 3);
          display: grid;
          grid-gap: 40px;
        }
        &[aria-colspan="2"] {
          width: calc(100% - calc(100% / 3));
        }
      }
    }
  }
  .card {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    padding: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #F8FBFF;
    border-radius: 16px;
     
    &_padding {
      padding: 20px;
    }

    img {
      max-width: 250px;
      margin: auto;
    }
    .text {
      flex: 1;
    }
    .user {
      display: flex;
      width: 100%;
      margin-top: 60px;
      .icon {
        width: 55px;
        img {
          max-width: 55px;
          height: auto;
        }
      }
      .info {
        flex: 1;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        padding-left: 15px;
        b {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          opacity: 0.7;
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .root {
    .wrapper {
      .grid {
        & > .col {
          width: 100% !important;
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
  .root {
    padding-top: 32px;
    padding-bottom: 32px;
    .wrapper {
      .title {
        font-weight: 800;
        font-size: 18px;
        line-height: 132%;
      }
      .grid {
        padding-top: 24px;
        & > .col {
          flex-direction: row;
          &.inline {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(96px, calc(50% - 20px)));
            min-width: 96px;
          }
        }
      }
    }
    .card {
      padding: 16px;
      img {
        max-width: 100%;
      }
    }
  }
}
