$black: #181818;
$blue-grey: #97adc6;
$grey: #dfe5eb;
$blue: #0084ff;
$white: #ffffff;
$ex-white: #f8fbff;
$yellow: #ffd12d;
$pink: #ffb9f8;
$green: #93e6cf;
$dodger-blue: #0084ff;
$riptide: #93e6cf;
$violet: #9620BF;
$violet-bg: #DAB7E7;
$violet-bg2: #FFDAFB;
$error: #f24822;

$paddingDropdownItems: 25px;
