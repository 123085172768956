.root {
  padding-bottom: 160px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .root {
    padding-bottom: 80px;

    a {
      width: 100%;
    }
  }
}
