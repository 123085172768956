.root {
  margin-bottom: 80px;
  .title {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 112%;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding-top: 90px;
    .tag {
      background: #FFB9F8;
      &[aria-color="yellow"] {
        background: #FFD12D;
      }
      &[aria-color="green"] {
        background: #93E6CF;
      }
      border: 1px solid #181818;
      border-radius: 16px;
      position: absolute;
      right: -20px;
      top: -40px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-size: 17px;
      line-height: 154%;
      padding: 14px 20px;
    }
    .card {
      background: #F8FBFF;
      border: 1px solid #DFE5EB;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      padding: 30px 30px;
      margin-bottom: 1rem;
      max-width: 400px;
      .heading {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 60px;
        label {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 22px;
          line-height: 132%;
        }
      }
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 154%;
      margin: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .root {
    margin-bottom: 40px;
    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 132%;
    }
    .cards {
      padding-top: 64px;
      .tag {
        right: 0px;
      }
    }
  }
}
