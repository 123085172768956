.root {
  margin-bottom: 60px;
  .title {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 112%;
    text-align: center;
  }
  .tags {
    display: flex;
    gap: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
    & > span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 154%;
      padding: 15px 25px 16px;

      background: #F8FBFF;
      border: 1px solid #DFE5EB;
      border-radius: 16px;
    }
  }
  .card {
    background: #F8FBFF;
    border: 1px solid #DFE5EB;
    border-radius: 16px;
    padding: 40px;
    margin-bottom: 1rem;
    .list {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .item {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 20px;
        label {
          background: #FFFFFF;
          padding: 15px 25px 16px;
          border: 1px solid #DFE5EB;
          border-radius: 16px;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 121%;
          display: flex;
          align-items: center;
        }
        .value {
          font-family: 'Raleway', sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 26px;
          line-height: 112%;
          margin-bottom: 5px;
        }
        span {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 154%;
          opacity: 0.7;
        }
      }
    }
    .images {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      .col {
        display: flex;
        gap: 20px;
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .root {
    .tags {
      flex-direction: column;
    }
    .card {
      padding: 0;
      .list {
        flex-direction: column;
        gap: 0;
        .item {
          border-bottom: 3px solid #181818;
          border-radius: 20px;
          padding: 40px;
        }
      }
      .images {
        flex-direction: column;
        .col {
          justify-content: center;
          flex-direction: row;
          padding-top: 40px;
          padding-bottom: 40px;
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {
  .root {
    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 132%;
    }
    .tags span {
      border: 0;
      background: #F8FBFF;
      border-radius: 10px;
    }
    .card {
      .list .item {
        flex-direction: column;
        padding: 20px;
        border-bottom: 0;
        border-radius: 0;
        label {
          font-weight: 400;
          font-size: 18px;
          line-height: 121%
        }
        .value {
          font-weight: 800;
          font-size: 30px;
          line-height: 112%;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 154%
        }
      }
      .images {
        padding-top: 20px;
        gap: 0;
        .col {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        .col:nth-child(2) {
          gap: 10px;
          img {
            max-width: 80px;
          }
        }
      }
    }
  }
}
