@import "~styles/variables";

.tabs {
  margin-bottom: 80px;
  position: relative;
  & .header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 72px;

    button {
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      padding-bottom: 8px;
      text-align: center;
      border: none;
      cursor: pointer;
      background: transparent;
      position: relative;
      color: #97adc6;
      border-bottom: 2px #97adc6 solid;
      transition: color 0.2s ease;

      &:focus,
      &:active {
        outline: none;
      }

      &:global(._active) {
        color: $black;
        border-color: $blue;
      }
    }
  }
  .prevButton {
    position: absolute;
    left: 0;
    top: 50%;
  }
  .nextButton {
    position: absolute;
    right: 0;
    top: 50%;
    color: red;
  }

  .nextButton, .prevButton {
    color: #181818;
    cursor: pointer;
    & > svg {
      border-radius: 50%;
      box-shadow: 0px 4px 20px rgba(24, 24, 24, 0.08);
    }
    &:hover {
      color: #0084FF;
    }
  }

  & .content {
    max-width: 1008px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    & .tab {
      display: none;

      &:global(._active) {
        display: block;
      }
    }
  }

  & .laptop {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    & .laptop {
      display: block;
    }

    .nextButton, .prevButton { display: none; }

    & .header {
      display: none;
    }

    & .content {
      display: none;

      & .tab {
        display: block;
      }
    }

    & .slider {
      margin: 0 -20px;
      padding-bottom: 8px;

      & :global(.slick-track) {
        & > div {
          padding: 0 20px;
        }
      }

      & :global(.slick-slide) {
        img {
          display: inline-block !important;
        }
      }

      & :global(.slick-dots) {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          width: 12px;
          height: 12px;
          margin: 0 4px;

          &:global(.slick-active) {
            div {
              background-color: $blue !important;
            }
          }
        }
      }
    }
  }
}
