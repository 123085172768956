.root {
  padding-top: 80px;
  &.first {
    background: #fff;
  }
  &.second {
    background: #F8FBFF;
  }
  .heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    & > div:first-child {
      flex: 1;
    }
    .title {
      font-family: 'Raleway', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 112%;
      text-align: center;
    }
    .description {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      color: #97ADC6;
      max-width: 530px;
      text-align: center;
    }
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 992px) {
  .root {
    padding-top: 80px;
    .heading {
      flex-direction: column;
      .description {
        max-width: 100%;
      }
    }
  }
}


@media screen and (max-width: 640px) {
  .root {
    padding-top: 40px;
    .heading {
      gap: 24px;
      padding-bottom: 0;
      .title {
        font-weight: 800;
        font-size: 24px;
        line-height: 132%;
      }
    }
  }
}
