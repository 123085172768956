.root {
  .wrapper {
    width: 100%;
    margin: auto;
  }
  .title {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #97ADC6;
  }
  .apps {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    flex-wrap: nowrap;
    .list {
      display: grid;
      grid-template-columns: repeat(104, 50px);
      align-items: center;
      align-content: center;
      grid-gap: 20px;
      padding: 40px 0 40px;
      animation: marquee 140s linear infinite;
      margin-right: 30px;
      .icon {
        display: block;
      }
    }
  }
}


@keyframes marquee {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-100%)
  }
}

@media screen and (max-width: 640px) {
  .root {
    padding-top: 32px;
    .title {
      font-weight: 800;
      font-size: 18px;
      line-height: 132%;
    }
    .apps {
      .list {
        padding: 32px 0;
        grid-template-columns: repeat(104, 40px);
        grid-gap: 10px;
      }
    }
  }
}
