.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  gap: 20px;
  .content {
    flex: 1;
    max-width: 384px;
    label {
      display: block;
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .root {
    padding-top: 40px;
    padding-bottom: 40px;
    flex-direction: column;
    .content {
      max-width: 100%;
    }
  }
}


@media screen and (max-width: 640px) {
  .root {
    padding-top: 40px;
    padding-bottom: 0px;
    flex-direction: column-reverse;
    .content {
      label {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
