.root {
  padding-top: 160px;
  .title {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 112%;
    text-align: center;
    padding-bottom: 80px;
  }
  .posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 160px;
    gap: 40px;
    .post {
      flex: 1;
      max-width: 375px;
      cursor: pointer;
      background-color: #fff;
      .poster {
        display: block;
        padding-bottom: 20px;

        img {
          border-radius: 20px;
        }

      }
      label {
        cursor: pointer;
        font-family: "Raleway", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 132%;
      }
    }
  }
}

.block {
  background: url("./assets/bg.svg") 95% 50% #0084ff no-repeat;
  border-radius: 24px;
  padding: 50px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 120%;
    color: #fff;
    &:nth-child(2n) {
      display: table;
      max-width: 450px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
      padding-right: 1rem;
      padding-left: 1rem;
      color: #0084ff;
    }
  }
  .actions {
    display: flex;
    gap: 40px;
    padding-top: 40px;

    .button {
      cursor: pointer;
      background: #ffffff;
      box-shadow: 0 8px 12px rgba(0, 132, 255, 0.2);
      border-radius: 8px;
      padding: 8px 32px;
      color: #0084ff;
      border: 1px solid #ffffff;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      &.link {
        background: #0084ff;
        color: #ffffff;
      }
    }
  }
}

.block_alt {
  min-height: 220px;
  background: url("./assets/bg-alt.svg") 95% 50% #0084ff no-repeat;
}

@media screen and (max-width: 640px) {
  .root {
    padding-top: 80px;
    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 132%;
      padding-bottom: 40px;
    }
    .posts {
      flex-direction: column;
      align-items: center;
      margin-bottom: 80px;
      .post {
        label {
          font-weight: 800;
          font-size: 18px;
          line-height: 132%;
        }
      }
    }
  }
  .block {
    background: #0084ff;
    padding: 32px 26px;
    .label {
      font-weight: 800;
      font-size: 24px;
      line-height: 132%;
      margin-top: 8px;

      &:nth-child(2n) {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
    }
    .actions {
      flex-direction: column;
      gap: 16px;
      padding-top: 32px;

      a {
        button {
          width: 100%;
        }
      }
    }
  }
}
