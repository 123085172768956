.padding {
  padding-bottom: 80px;
}

.root {
  background-size: cover;
  padding-top: 0px;
  .title {
    font-family: 'Raleway', sans-serif;
    font-size: 36px;
    line-height: 132%;
    color: #97ADC6;
  }
  .content {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    .col {
      flex: 1;
      min-width: 275px;
      display: flex;
      flex-direction: column;
      align-items: center;
      label {
        font-family: 'Raleway', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 112%;
        padding-bottom: 20px;
      }
      span {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 150%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .root {
    .content {
      .col {
        label {
          padding-bottom: 10px;
        }
      }
    }
  }
}


@media screen and (max-width: 640px) {

  .padding {
    padding-bottom: 32px;
  }

  .root {
    padding-top: 32px;
  
    .title {
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }
    .content {
      padding-top: 32px;
      padding-bottom: 32px;
      .col {
        flex: 1 1;
        min-width: 100px;
        label {
          font-weight: 800;
          font-size: 32px;
          line-height: 112%;
        }
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }
}
